<template>
  <div class="content_list">
    <ul>
      <li class="float zhong" v-for="(item, index) in a" :key="index" @click="helpDetails(item.Id)">
        <h6>{{ item.Title }}</h6>
        <div class="content_fgruop zhong center">
          <p>查看</p>
          <img src="../images/go.png" />
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/public.scss";
@import "@/styles/help.scss";
</style>
<script>
export default {
  name: '',
  components: {},
  props: {
    a: {
      type: Array,
      default: () => []
    },
    type: String
  },
  data() {
    return {

    };
  },
  methods: {
    helpDetails(id) {
      this.$router.push({ name: 'helpDetails', query: { id: id, type: this.type } });
    }
  }
};
</script>
