<template>
    <div class="help widthCen content">
        <h6>当前位置：帮助中心</h6>
        <div class="article_white">
            <div class="help_nei float">
                <div class="content_div">
                    <div class="content_title">
                        <img src="../../images/daili.png" />
                    </div>
                    <!-- 列表 -->
                    <help-list
                        :a="list1"
                        :type="'代理'"
                    ></help-list>
                    <div class="page-container">
                        <el-pagination
                            :hide-on-single-page="true"
                            background
                            small
                            layout="prev, pager, next"
                            :current-page.sync="agent.page"
                            :page-size="agent.pageSize"
                            :total="agentTotal"
                            @current-change="getList(1)"
                        >
                        </el-pagination>
                    </div>
                </div>
                <div class="content_div content_dTwo">
                    <div class="content_title">
                        <img src="../../images/dailia.png" />
                    </div>
                    <!-- 列表 -->
                    <help-list
                        :a="list2"
                        :type="'学员'"
                    ></help-list>
                    <div class="page-container">
                        <el-pagination
                            :hide-on-single-page="true"
                            background
                            small
                            layout="prev, pager, next"
                            :current-page.sync="student.page"
                            :page-size="student.pageSize"
                            :total="studentTotal"
                            @current-change="getList(2)"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/help.scss';
.page-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
</style>
<script>
import helpList from '../../components/help.vue';
export default {
    name: '',
    components: {
        helpList
    },
    data() {
        return {
            agent: {
                page: 1,
                pageSize: 10,
                listType: 3,
                type: 4
            },
            agentTotal: 1,
            student: {
                page: 1,
                pageSize: 10,
                listType: 3,
                type: 5
            },
            studentTotal: 1,
            list1: [],
            list2: []
        };
    },
    created() {
        this.getList(1);
        this.getList(2);
    },
    methods: {
        /**
         * type 1: 代理 2：学员
         */
        getList(type) {
            switch (type) {
                case 1:
                    this.$api.getHelpList(this.agent).then(res => {
                        // console.log('res1:', res);
                        this.list1 = res.data;
                        this.agentTotal = res.TotalCount;
                    });
                    break;
                case 2:
                    this.$api.getHelpList(this.student).then(res => {
                        // console.log('res2:', res);
                        this.list2 = res.data;
                        this.studentTotal = res.TotalCount;
                    });
                    break;
            }
        }
    }
};
</script>
